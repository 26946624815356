import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import OdmenyGuard from "../components/atoms/route-guard/OdmenyGuard";
import { Outlet } from "react-router-dom";

// dashboard routing
const OdmenyPage = Loadable(lazy(() => import("../components/pages/odmeny")));
const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const OdmenyRoutes = {
  path: "/",
  element: (
    <OdmenyGuard>
      <Outlet />
    </OdmenyGuard>
  ),
  children: [
    {
      path: "/app/odmeny",
      element: <OdmenyPage />,
    },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default OdmenyRoutes;

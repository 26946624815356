import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import { Outlet } from "react-router-dom";
import RezervacieAutGuard from "../components/atoms/route-guard/RezervacieAutGuard";

// dashboard routing
const RezervaciaAutPage = Loadable(
  lazy(() => import("../components/pages/rezervaciaAut"))
);
const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const RezervacieAutRoutes = {
  path: "/",
  element: (
    <RezervacieAutGuard>
      <Outlet />
    </RezervacieAutGuard>
  ),
  children: [
    {
      path: "/app/rezervace-aut",
      element: <RezervaciaAutPage />,
    },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default RezervacieAutRoutes;

// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconAlertTriangle, IconCrane } from "@tabler/icons";

// constant
const icons = {
  IconAlertTriangle,
  IconCrane,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const poruchyMenu = {
  id: "poruchyMenu",
  title: <FormattedMessage id="failures-panel" />,
  type: "group",
  children: [
    {
      id: "poruchy",
      title: <FormattedMessage id="failures" />,
      mainTitle: <FormattedMessage id="failures" />,
      type: "item",
      url: "/app/poruchy",
      icon: icons.IconAlertTriangle,
      breadcrumbs: false,
    },
    {
      id: "stroje",
      title: <FormattedMessage id="machines" />,
      mainTitle: <FormattedMessage id="machines" />,
      type: "item",
      url: "/app/stroje",
      icon: icons.IconCrane,
      breadcrumbs: false,
    },
  ],
};

export default poruchyMenu;

import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import AuthGuard from "../components/atoms/route-guard/AuthGuard";
import { Outlet } from "react-router-dom";

// login routing
const InteraktivePO = Loadable(
  lazy(() => import("../components/pages/cenovaPonuka/interaktive"))
);
const InteraktivePOPrint = Loadable(
  lazy(() => import("../components/pages/cenovaPonuka/interactivePrint"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));

// ==============================|| AUTH ROUTING ||============================== //

const InteraktiveRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "/cenova-nabidka/interaktive/:base64",
      element: <InteraktivePO />,
    },
    {
      path: "/cenova-nabidka/interaktive-print/:base64",
      element: <InteraktivePOPrint />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default InteraktiveRoutes;

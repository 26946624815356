// material-ui
import { useTheme } from "@mui/material/styles";

import logoDark from "../../assets/logo/logoDark.svg";
import logo from "../../assets/logo/logo.svg";

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ width = "150", height = "auto", shadow = false }) => {
  const theme = useTheme();
  const shadowStyle = {
    WebkitFilter: "drop-shadow( 2px 2px 2px rgba(0, 0, 0, .35))",
    filter: "drop-shadow( 2px 2px 2px rgba(0, 0, 0, .35))",
    /* Similar syntax to box-shadow */
  };
  return (
    <img
      src={theme.palette.mode === "dark" ? logoDark : logo}
      alt="obs"
      width={width}
      height={height}
      style={shadow ? shadowStyle : {}}
    />
  );
};

export default Logo;

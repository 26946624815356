import { createStore } from "redux";
import { persistStore } from "redux-persist";
import reducer from "./reducer";

// ==============================|| REDUX - MAIN STORE ||============================== //
/* eslint-disable no-underscore-dangle */
const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const persister = persistStore(store);
/* eslint-enable */
export { store, persister };

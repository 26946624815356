import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

const LOCAL_STORAGE_AUTH_KEY = "AUTH_KEY";

var initialState = {
  token: null,
  refresh: null,
  user: null,
  exp: null,
};

const AuthContext = createContext(
  createContextValue({
    token: initialState.token,
    refres: initialState.refresh,
    user: initialState.user,
    exp: initialState.exp,
    setState: () =>
      console.error("You are using AuthContext without AuthProvider!"),
  })
);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [state, setState] = usePersistedAuth(initialState);

  const contextValue = useMemo(() => {
    const { token, refresh, user, exp } = state;
    return createContextValue({ token, refresh, user, exp, setState });
  }, [state, setState]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

function createContextValue({ token, refresh, user, exp, setState }) {
  return {
    token,
    refresh,
    user,
    exp,
    signin: ({ token, refresh, user, exp }) => {
      setState({ token, refresh, user, exp });
    },
    signout: () => {
      setState({ token: null, refresh: null, user: null, exp: null });
      localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
    },
  };
}

function usePersistedAuth(defaultState) {
  const [state, setStateRaw] = useState(() => getStorageState(defaultState));

  const setState = useCallback((newState) => {
    setStateRaw(newState);
    setStorageState(newState);
  }, []);

  return [state, setState];
}

function getStorageState(defaultState) {
  if (!window.localStorage) {
    return defaultState;
  }

  const rawData = window.localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
  const decodedRawDate = rawData && toUtf8(rawData);
  if (!decodedRawDate) {
    return defaultState;
  }

  try {
    const { user, refresh, token, exp } = JSON.parse(decodedRawDate);

    if (token && user && refresh && exp) {
      return { token, refresh, user, exp };
    }
  } catch (e) {}

  return defaultState;
}

function toBase64(string) {
  return new Buffer.from(string).toString("base64");
}
function toUtf8(base64) {
  return new Buffer.from(base64, "base64").toString("utf8");
}

function setStorageState(newState) {
  if (!window.localStorage) {
    return;
  }

  window.localStorage.setItem(
    LOCAL_STORAGE_AUTH_KEY,
    toBase64(JSON.stringify(newState))
  );
}

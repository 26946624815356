// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconTruckDelivery } from "@tabler/icons";

// constant
const icons = {
  IconTruckDelivery,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const objednavkyMenu = {
  id: "objednavkyMenu",
  title: <FormattedMessage id="orders-panel" />,
  type: "group",
  children: [
    {
      id: "objednavky",
      title: <FormattedMessage id="orders" />,
      mainTitle: <FormattedMessage id="orders" />,
      type: "item",
      url: "/app/objednavky",
      icon: icons.IconTruckDelivery,
      breadcrumbs: false,
    },
  ],
};

export default objednavkyMenu;

import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import OdmenyGuard from "../components/atoms/route-guard/OdmenyGuard";
import { Outlet } from "react-router-dom";
import ObjednavkyGuard from "../components/atoms/route-guard/ObjednavkyGuard";

// dashboard routing
const ObjednavkyPage = Loadable(
  lazy(() => import("../components/pages/objednavky"))
);
const ObjednavkyDetailPage = Loadable(
  lazy(() => import("../components/pages/objednavky/detail"))
);
const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const ObjednavkyRoutes = {
  path: "/",
  element: (
    <ObjednavkyGuard>
      <Outlet />
    </ObjednavkyGuard>
  ),
  children: [
    {
      path: "/app/objednavky",
      element: <ObjednavkyPage />,
    },
    {
      path: "/app/objednavky/:id",
      element: <ObjednavkyDetailPage />,
    },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default ObjednavkyRoutes;

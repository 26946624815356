import admin from "./admin";
import detailyMenu from "./detaily";
import customerMenu from "./customer";
import defaultMenu from "./defaultMenu";
import organizacnyDiagram from "./organizacnyDiagram";
import poruchyMenu from "./poruchy";
import projektyXXLMenu from "./projektyXXL";
import reklamaceMenu from "./reklamace";
import odmenyMenu from "./odmeny";
import montazniSkupinaMenu from "./montazniSkupina";
import rezervacieAutMenu from "./rezervacieAut";
import windowsMenu from "./windows";
import accountingMenu from "./accounting";
import vykonMenu from "./vykonStredisk";
import objednavkyMenu from "./objednavky";
import kalendarMenu from "./kalendar";

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
  defaultItems: [defaultMenu],
  adminItems: [
    defaultMenu,
    accountingMenu,
    kalendarMenu,
    //rezervacieAutMenu,
    //windowsMenu,
    //montazniSkupinaMenu,
    vykonMenu,
    objednavkyMenu,
    //odmenyMenu,
    //poruchyMenu,
    reklamaceMenu,
    projektyXXLMenu,
    //customerMenu,
    detailyMenu,
    admin,
  ],
  customerItems: [customerMenu],
  reklamaceItems: [reklamaceMenu],
  poruchyItems: [poruchyMenu],
  organizacnyDiagramItems: [organizacnyDiagram],
  projektyXXLItems: [projektyXXLMenu],
  detailyItems: [detailyMenu],
  odmenyItems: [odmenyMenu],
  montazniSkupinaItems: [montazniSkupinaMenu],
  rezervacieAutItems: [rezervacieAutMenu],
  windowsItems: [windowsMenu],
  accountingItems: [accountingMenu],
  vykonStrediskItems: [vykonMenu],
  objednavkyItems: [objednavkyMenu],
  kalendarItems: [kalendarMenu],
};

export default menuItems;

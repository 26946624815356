// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconCar } from "@tabler/icons";

// constant
const icons = {
  IconCar,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const rezervacieAutMenu = {
  id: "rezervacieAutMenu",
  title: <FormattedMessage id="car-reservation-panel" />,
  type: "group",
  children: [
    {
      id: "rezervace-aut",
      title: <FormattedMessage id="car-reservations" />,
      mainTitle: <FormattedMessage id="car-reservations" />,
      type: "item",
      url: "/app/rezervace-aut",
      icon: icons.IconCar,
      breadcrumbs: false,
    },
  ],
};

export default rezervacieAutMenu;

// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconAward } from "@tabler/icons";

// constant
const icons = {
  IconAward,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const odmenyMenu = {
  id: "odmenyMenu",
  title: <FormattedMessage id="rewards-panel" />,
  type: "group",
  children: [
    {
      id: "odmeny",
      title: <FormattedMessage id="rewards" />,
      mainTitle: <FormattedMessage id="rewards" />,
      type: "item",
      url: "/app/odmeny",
      icon: icons.IconAward,
      breadcrumbs: false,
    },
  ],
};

export default odmenyMenu;

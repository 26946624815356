// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconZoomMoney } from "@tabler/icons";

// constant
const icons = {
  IconZoomMoney,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const montazniSkupinaMenu = {
  id: "montazniSkupinaMenu",
  type: "group",
  title: <FormattedMessage id="contruction-group-panel" />,
  children: [
    {
      id: "vyhled-montaze-priradeny",
      title: <FormattedMessage id="vyhled-priradeny" />,
      type: "item",
      url: "/app/vyhled-montaze-priradeny",
      breadcrumbs: false,
      icon: icons.IconZoomMoney,
    },
  ],
};

export default montazniSkupinaMenu;

// third-party
import { FormattedMessage } from "react-intl";

// assets
import { Icon3dCubeSphere } from "@tabler/icons";

// constant
const icons = {
  Icon3dCubeSphere,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const detailyMenu = {
  id: "architektMenu",
  title: <FormattedMessage id="details-panel" />,
  type: "group",
  children: [
    {
      id: "katalog-detailov",
      title: <FormattedMessage id="details-catalog" />,
      mainTitle: <FormattedMessage id="details-catalog" />,
      type: "item",
      url: "/app/katalog-detailov",
      icon: icons.Icon3dCubeSphere,
      breadcrumbs: false,
    },
  ],
};

export default detailyMenu;

import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import { Outlet } from "react-router-dom";
import WindowsGuard from "../components/atoms/route-guard/WindowsGuard";

// dashboard routing
const PlanovanieZakazkyPage = Loadable(
  lazy(() => import("../components/pages/WINDOWS/planovanie"))
);
const WindowsProduktyPage = Loadable(
  lazy(() => import("../components/pages/WINDOWS/produkty"))
);
const VypisZakazokPage = Loadable(
  lazy(() => import("../components/pages/WINDOWS/vypisZakazok"))
);
const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const WindowsRoutes = {
  path: "/",
  element: (
    <WindowsGuard>
      <Outlet />
    </WindowsGuard>
  ),
  children: [
    {
      path: "/app/planovanie-zakazky",
      element: <PlanovanieZakazkyPage />,
    },
    {
      path: "/app/planovanie-produkty",
      element: <WindowsProduktyPage />,
    },
    { path: "/app/vypis-zakazok", element: <VypisZakazokPage /> },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default WindowsRoutes;

import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import AuthGuardNotCustomer from "../components/atoms/route-guard/AuthGuardNotCustomer";
import { Outlet } from "react-router-dom";

// dashboard routing
const DefaultPage = Loadable(
  lazy(() => import("../components/pages/defaultPage"))
);
const ZakaznikPage = Loadable(
  lazy(() => import("../components/pages/zakaznici"))
);
const ZakaznikDetailPage = Loadable(
  lazy(() => import("../components/pages/zakaznici/detail"))
);
const CenovaPonukaPage = Loadable(
  lazy(() => import("../components/pages/cenovaPonuka"))
);
const CenovaPonukaDetailPage = Loadable(
  lazy(() => import("../components/pages/cenovaPonuka/detail"))
);
const UkolyPage = Loadable(lazy(() => import("../components/pages/tasks")));
const AktivityPage = Loadable(
  lazy(() => import("../components/pages/activities"))
);
const ArchitektiPage = Loadable(
  lazy(() => import("../components/pages/architekti"))
);
const ArchitektDetailPage = Loadable(
  lazy(() => import("../components/pages/architekti/detail"))
);
const UnderConstruction = Loadable(
  lazy(() => import("../components/pages/maintenance"))
);
const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);

const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuardNotCustomer>
      <Outlet />
    </AuthGuardNotCustomer>
  ),
  children: [
    {
      path: "/app/nastenka",
      element: <DefaultPage />,
    },
    {
      path: "/app/obchodni-vztahy",
      element: <ZakaznikPage />,
    },
    {
      path: "/app/obchodni-vztahy/:zakaznikId",
      element: <ZakaznikDetailPage />,
    },
    {
      path: "/app/architekti",
      element: <ArchitektiPage />,
    },
    {
      path: "/app/architekti/:architektId",
      element: <ArchitektDetailPage />,
    },
    {
      path: "/app/zakazky",
      element: <CenovaPonukaPage />,
    },
    {
      path: "/app/zakazky/:cenovaNabidkaId",
      element: <CenovaPonukaDetailPage />,
    },
    {
      path: "/app/aktivity",
      element: <AktivityPage />,
    },
    {
      path: "/app/ukoly",
      element: <UkolyPage />,
    },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default MainRoutes;

// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconCalendarEvent } from "@tabler/icons";

// constant
const icons = { IconCalendarEvent };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const kalendarMenu = {
  id: "kalendarMenu",
  title: <FormattedMessage id="shared-calendar" />,
  type: "group",
  children: [
    {
      id: "kalendar",
      title: <FormattedMessage id="calendar" />,
      mainTitle: <FormattedMessage id="calendar" />,
      type: "item",
      url: "/app/kalendar",
      icon: icons.IconCalendarEvent,
      breadcrumbs: false,
    },
  ],
};

export default kalendarMenu;

/*function startConnection(onReady) {
    if (connection && connection.connected) {
      if (onReady) onReady();
      return;
    }
    connection = io();
    connection.on("connect", function () {
      connectionId = connection.id;
      if (onReady) onReady();
    });*/

import React, {
  createContext,
  useContext,
  useState,
  useLayoutEffect,
} from "react";
import io from "socket.io-client";
import Swal from "sweetalert2";

export const SocketIoContext = createContext({
  connectionId: null,
  connection: null,
});

export const useIO = () => {
  return useContext(SocketIoContext);
};

export const SocketIoProvider = ({ children, onReady }) => {
  const [{ connectionId, connection }, setConnection] = useState({
    connectionId: null,
    connection: null,
  });
  useLayoutEffect(() => {
    const newConnection = io(process.env.REACT_APP_BASE_PUBLIC);
    newConnection.on("connect", function () {
      setConnection({
        connectionId: newConnection.id,
        connection: newConnection,
      });
      if (onReady) onReady();
    });
    newConnection.on("SUCCESS_MESSAGE", (res) => {
      Swal.fire({
        icon: "success",
        title: "Vas pozadavek bol uspesne vykonany",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2200,
        text: res,
      });
    });
    /*return () => {
      connection && connection.disconnect();
    };*/
  }, [onReady]);

  const context = {
    connectionId,
    connection,
  };

  return (
    <SocketIoContext.Provider value={context}>
      {children}
    </SocketIoContext.Provider>
  );
};

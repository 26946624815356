import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project imports
//import config from "../../../../config";
import Logo from "../../../icons/Logo";
import { useDispatch, useSelector } from "react-redux";
import { MENU_OPEN } from "../../../../store/actions";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const dispatch = useDispatch();
  const path = useSelector((state) => state.customization.defaultPath);
  const pathId = path.split("/")[path.split("/").length - 1];
  return (
    <ButtonBase
      disableRipple
      component={Link}
      to={path}
      onClick={() => dispatch({ type: MENU_OPEN, id: pathId })}
    >
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;

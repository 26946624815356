const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: "",
  defaultPath: "/app/nastenka",
  customerDefaultPath: "/app/moje-cenove-nabidky",
  reklamaceDefaultPath: "/app/reklamace",
  poruchyDefaultPath: "/app/poruchy",
  projektyXXLDefaultPath: "/app/projekty-xxl",
  detailyDefaultPath: "/app/katalog-detailov",
  odmenyDefaultPath: "/app/odmeny",
  montazniSkupinaDefaultPath: "/app/vyhled-montaze-priradeny",
  windowsDefaultPath: "/app/planovanie-zakazky",
  accountigDefaultPath: "/app/analyza-projektov",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  outlinedFilled: true,
  theme: "light", // light, dark
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  i18n: "cs", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  poColumnsState: {
    id_cen_ponuka: true,
    nazov: true,
    id_zakaznik: true,
    id_user_projekt_mngr: true,
    miesto: true,
    m_2: true,
    datum_vytvorenia: true,
    datum_zamerania: false,
    datum_podpisu_SOD: false,
    predpokladany_datum_fakturace: false,
    stav: true,
    marze: true,
    vazeneTrzby: false,
  },
};

export default config;

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// project imports
import { useAuth } from "../../../utils/auth";
import { useEffect } from "react";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const RoleGuard = ({ children }) => {
  const { user } = useAuth();
  const haveAnyRole = user ? user.roles.length !== 0 : false;
  const isAdmin = user ? JSON.stringify(user.roles).includes("admin") : false;
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    if (user && !haveAnyRole && !isAdmin) {
      navigate("/app/not-allowed");
    }
  }, [user, navigate]);

  return children;
};

RoleGuard.propTypes = {
  children: PropTypes.node,
};

export default RoleGuard;

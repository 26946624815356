import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import AuthGuard from "../components/atoms/route-guard/AuthGuard";
import { Outlet } from "react-router-dom";

// dashboard routing
const CustomerPriceOffers = Loadable(
  lazy(() => import("../components/pages/cenovaPonuka/forCustomer"))
);
const CustomerContractsPage = Loadable(
  lazy(() => import("../components/pages/cenovaPonuka/forCustomer/contracts"))
);
const CustomerInvoicesPage = Loadable(
  lazy(() => import("../components/pages/cenovaPonuka/forCustomer/invoices"))
);
const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const CustomerRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "/app/moje-cenove-nabidky",
      element: <CustomerPriceOffers />,
    },
    {
      path: "/app/moje-smlouvy",
      element: <CustomerContractsPage />,
    },
    {
      path: "/app/moje-faktury",
      element: <CustomerInvoicesPage />,
    },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default CustomerRoutes;

import { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";

// routes
import LoginRoutes from "./LoginRoutes";
import AdminRoutes from "./AdminRoutes";
import MainRoutes from "./MainRoutes";
import InteraktiveRoutes from "./InteraktiveRoutes";
import Loadable from "../components/molecules/Berry/Loadable";
import CustomerRoutes from "./CustomerRoutes";
import ReklamaceRoutes from "./ReklamaceRoutes";
import PoruchyRoutes from "./PoruchyRoutes";
import OrganizaciaRoutes from "./OrganizaciaRoutes";
import ProjektyXXLRoutes from "./ProjektyXXLRoutes";
import WithRolesRoutes from "./WithRolesRoutes";
import { useSelector } from "react-redux";
import DetailyRoutes from "./detailyRoutes";
import OdmenyRoutes from "./odmenyRoutes";
import MainLayout from "../components/organisms/BerryLayout";
import RezervacieAutRoutes from "./RezervacieAutRoutes";
import WindowsRoutes from "./WindowsRoutes";
import AccountingRoutes from "./AccountingRoutes";
import VykonRoutes from "./VykonRoutes";
import ObjednavkyRoutes from "./objednavkyRoutes";
import KalendarRoutes from "./KalendarRoutes";

const Error = Loadable(lazy(() => import("../components/pages/errorPage")));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const path = useSelector((state) => state.customization.defaultPath);
  return useRoutes([
    {
      path: "/",
      element: <MainLayout path={path} />,
      children: [
        MainRoutes,
        AdminRoutes,
        CustomerRoutes,
        ReklamaceRoutes,
        PoruchyRoutes,
        OrganizaciaRoutes,
        ProjektyXXLRoutes,
        DetailyRoutes,
        WithRolesRoutes,
        OdmenyRoutes,
        VykonRoutes,
        RezervacieAutRoutes,
        WindowsRoutes,
        AccountingRoutes,
        ObjednavkyRoutes,
        KalendarRoutes,
      ],
    },
    LoginRoutes,
    InteraktiveRoutes,
    { path: "*", element: <Error /> },
  ]);
}

import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import AdminGuard from "../components/atoms/route-guard/AdminGuard";
import { Outlet } from "react-router-dom";

// login option 1 routing
const UsersPage = Loadable(lazy(() => import("../components/pages/usersPage")));
const GroupsPage = Loadable(
  lazy(() => import("../components/pages/groupsPage"))
);
const GroupsDetailPage = Loadable(
  lazy(() => import("../components/pages/groupsDetailPage"))
);
const RolesPage = Loadable(lazy(() => import("../components/pages/rolesPage")));
const PoziciePage = Loadable(lazy(() => import("../components/pages/pozicie")));
const OrganizaciePage = Loadable(
  lazy(() => import("../components/pages/organizacie"))
);
// const LabelsPage = Loadable(
//   lazy(() => import("../components/pages/labelsPage"))
// );
const TypAktivityPage = Loadable(
  lazy(() => import("../components/pages/typAktivityPage"))
);

const KategoriePage = Loadable(
  lazy(() => import("../components/pages/kategoriePage"))
);
const TestProductsPage = Loadable(
  lazy(() => import("../components/pages/testProductsPage"))
);
// const ZdrojePoptavkyPage = Loadable(
//   lazy(() => import("../components/pages/zdrojePoptavkyPage"))
// );
const ProductsPage = Loadable(
  lazy(() => import("../components/pages/products"))
);
const ProductsDetailPage = Loadable(
  lazy(() => import("../components/pages/products/detail"))
);
// const MoznostiUsporyPage = Loadable(
//   lazy(() => import("../components/pages/moznostiUspory"))
// );
const LogsPage = Loadable(lazy(() => import("../components/pages/logs")));
const HeroImagePage = Loadable(
  lazy(() => import("../components/pages/heroImage"))
);
const ObchodnePodmienkyPage = Loadable(
  lazy(() => import("../components/pages/obchodnePodmienky"))
);
// const CarManagementPage = Loadable(
//   lazy(() => import("../components/pages/rezervaciaAut/carManagementPage"))
// );
const ModelParametersPage = Loadable(
  lazy(() => import("../components/pages/modelParameters"))
);

const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AdminRoutes = {
  path: "/",
  element: (
    <AdminGuard>
      <Outlet />
    </AdminGuard>
  ),
  children: [
    {
      path: "/app/uzivatelia",
      element: <UsersPage />,
    },
    {
      path: "/app/skupiny",
      element: <GroupsPage />,
    },
    {
      path: "/app/skupiny/:groupId",
      element: <GroupsDetailPage />,
    },
    {
      path: "/app/role",
      element: <RolesPage />,
    },
    {
      path: "/app/pozicie",
      element: <PoziciePage />,
    },
    {
      path: "/app/organizacie",
      element: <OrganizaciePage />,
    },
    {
      path: "/app/kategorie",
      element: <KategoriePage />,
    },
    {
      path: "/app/produkty",
      element: <ProductsPage />,
    },
    {
      path: "/app/produkty/:produktId",
      element: <ProductsDetailPage />,
    },
    // {
    //   path: "/app/zdroje-poptavky",
    //   element: <ZdrojePoptavkyPage />,
    // },
    // {
    //   path: "/app/label",
    //   element: <LabelsPage />,
    // },
    {
      path: "/app/hero-image",
      element: <HeroImagePage />,
    },
    {
      path: "/app/typ-aktivity",
      element: <TypAktivityPage />,
    },
    // {
    //   path: "/app/moznosti-uspory",
    //   element: <MoznostiUsporyPage />,
    // },
    { path: "/app/texty-cn", element: <ObchodnePodmienkyPage /> },
    {
      path: "/app/moduly",
      element: <TestProductsPage />,
    },
    // { path: "/app/sprava-aut", element: <CarManagementPage /> },
    { path: "/app/parametre-modelov", element: <ModelParametersPage /> },
    {
      path: "/app/logs",
      element: <LogsPage />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default AdminRoutes;

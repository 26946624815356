import { lazy } from "react";
import { Outlet } from "react-router-dom";
import RoleGuard from "../components/atoms/route-guard/RoleGuard";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";

// dashboard routing
const UserProfile = Loadable(
  lazy(() => import("../components/pages/userProfile"))
);

const CustomizationPage = Loadable(
  lazy(() => import("../components/pages/customization"))
);

const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const WithRolesRoutes = {
  path: "/",
  element: (
    <RoleGuard>
      <Outlet />
    </RoleGuard>
  ),
  children: [
    {
      path: "/app/uzivatelsky-profil",
      element: <UserProfile />,
    },
    {
      path: "/app/uzivatelsky-profil/:userId",
      element: <UserProfile />,
    },
    {
      path: "/app/design-customization",
      element: <CustomizationPage />,
    },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default WithRolesRoutes;

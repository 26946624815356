// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconReportAnalytics } from "@tabler/icons";

// constant
const icons = { IconReportAnalytics };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const accountingMenu = {
  id: "accountingMenu",
  title: <FormattedMessage id="accounting" />,
  type: "group",
  children: [
    {
      id: "analyza-projektov",
      title: <FormattedMessage id="project-analysis" />,
      mainTitle: <FormattedMessage id="project-analysis" />,
      type: "item",
      url: "/app/analyza-projektov",
      icon: icons.IconReportAnalytics,
      breadcrumbs: false,
    },
  ],
};

export default accountingMenu;

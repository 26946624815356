// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconCalendarEvent, IconDoor, IconList } from "@tabler/icons";

// constant
const icons = {
  IconCalendarEvent,
  IconDoor,
  IconList,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const windowsMenu = {
  id: "windowsMenu",
  title: <FormattedMessage id="windows-panel" />,
  type: "group",
  children: [
    {
      id: "planovanie-zakazky",
      title: <FormattedMessage id="order-planning" />,
      mainTitle: <FormattedMessage id="order-planning" />,
      type: "item",
      url: "/app/planovanie-zakazky",
      icon: icons.IconCalendarEvent,
      breadcrumbs: false,
    },
    {
      id: "vypis-zakazok",
      title: <FormattedMessage id="orders-list" />,
      mainTitle: <FormattedMessage id="orders-list" />,
      type: "item",
      url: "/app/vypis-zakazok",
      icon: icons.IconList,
      breadcrumbs: false,
    },
    {
      id: "planovanie-produkty",
      title: <FormattedMessage id="windows-products" />,
      mainTitle: <FormattedMessage id="windows-products" />,
      type: "item",
      url: "/app/planovanie-produkty",
      icon: icons.IconDoor,
      breadcrumbs: false,
    },
  ],
};

export default windowsMenu;

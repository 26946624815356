import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
} from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { useAuth } from "./auth";
import { decryptResponse } from "./functions";

const globalApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
});

const ApiStateContext = createContext(globalApiInstance);

export function useApi() {
  return useContext(ApiStateContext);
}

export function ApiProvider({ children }) {
  const api = globalApiInstance;
  useSetAuthorizationHeader(api);
  useInstallSignoutApiInterceptror(api);
  useDecryptInterceptor(api);

  return (
    <ApiStateContext.Provider value={api}>{children}</ApiStateContext.Provider>
  );
}

function useSetAuthorizationHeader(api) {
  const { token, refresh } = useAuth();

  // Using `useLayoutEffect` instead of `useEffect` because it is triggered
  // earlier then other `useEffect` calls that may already use `api`.
  useLayoutEffect(() => {
    api.defaults.headers.common = {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };
    if (!token) {
      delete api.defaults.headers.common["Authorization"];
    } else {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      api.defaults.headers.common["Refresh"] = refresh;
    }
  }, [token, api, refresh]);
}

function useInstallSignoutApiInterceptror(api) {
  const auth = useAuth();
  const signoutRef = useRef(auth.signout);
  const authRef = useRef(auth);
  const [jsonToken, setJsonToken] = useState(null);

  useEffect(() => {
    if (jsonToken && jsonToken.token && jsonToken.refresh) {
      authRef.current.signin({
        token: jsonToken.token,
        refresh: jsonToken.refresh,
        user: authRef.current.user,
        exp: jsonToken.exp,
      });
    }
  }, [jsonToken, authRef]);

  useEffect(() => {
    signoutRef.current = auth.signout;
  }, [auth.signout]);
  useEffect(() => {
    authRef.current = auth;
  }, [auth]);

  useEffect(() => {
    const signoutInterceptr = api.interceptors.response.use(
      (response) => {
        if (
          response.headers["refresh-token"] &&
          !response.request.responseURL.includes("logout")
        ) {
          setJsonToken(JSON.parse(response.headers["refresh-token"]));
        }
        return response;
      },
      (error) => {
        const isInvalidTokenResponse =
          error && error.response && error.response.status === 401;
        if (isInvalidTokenResponse && signoutRef.current) {
          Swal.fire({
            icon: "error",
            title: "Boli ste odhlaseny!",
            text: "Token prihlásenia vypršal budete presmerovaný na stránku prihlásenia",
            showConfirmButton: false,
            timer: 2500,
            willClose: () => {
              signoutRef.current();
            },
          });
        }
        return Promise.reject(error);
      }
    );
    return () => {
      api.interceptors.request.eject(signoutInterceptr);
    };
  }, [api]);
}

const useDecryptInterceptor = (api) => {
  useEffect(() => {
    const decryptIterceptor = api.interceptors.response.use(
      (res) => {
        if (
          res &&
          res.data &&
          res.data.result &&
          typeof res.data.result === "string"
        ) {
          const newResult = decryptResponse(res);
          res.data.result = newResult;
          return res;
        } else {
          return res;
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return () => api.interceptors.response.eject(decryptIterceptor);
  }, [api]);
};

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// project imports
import { useAuth } from "../../../utils/auth";
import { useEffect } from "react";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const VykonGuard = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    if (
      user &&
      !user.roles.some((role) => role.nazov === "vykon_stredisk") &&
      !user.roles.some((role) => role.nazov === "admin")
    ) {
      navigate("/app/not-allowed");
    }
  }, [user, navigate]);

  return children;
};

VykonGuard.propTypes = {
  children: PropTypes.node,
};

export default VykonGuard;

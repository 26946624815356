import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store, persister } from "./store";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import config from "./config";
import "./assets/scss/style.scss";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const construction = process.env.REACT_APP_TYPE === "test";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
          <BrowserRouter basename={config.basename}>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import MenuList from "./MenuList";
import LogoSection from "../LogoSection";
import { drawerWidth } from "../../../../store/constant";

import menuItem from "../menu-items";
import { useAuth } from "../../../../utils/auth";
import { useEffect, useState } from "react";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const { user } = useAuth();
  const isAdmin = user && user.roles.some((role) => role.nazov === "admin");
  const isCustomer =
    user &&
    user.roles &&
    JSON.stringify(user.roles).includes("zakaznik") &&
    user.roles.length === 1;
  const haveOrganization = user && user.organizacia ? true : false;
  const haveObchodRole = user
    ? JSON.stringify(user.roles).includes("obchod")
    : false;
  const haveProjektyXXLRole = user
    ? JSON.stringify(user.roles).includes("projektyXXL")
    : false;
  const haveReklamaceRole = user
    ? JSON.stringify(user.roles).includes("reklamace")
    : false;
  const havePoruchyRole = user
    ? JSON.stringify(user.roles).includes("poruchy")
    : false;
  const haveDetailyRole = user
    ? JSON.stringify(user.roles).includes("detaily")
    : false;
  const haveOdmenyRole = user
    ? JSON.stringify(user.roles).includes("odmeny")
    : false;
  const haveMontazniSkupinaRole = user
    ? JSON.stringify(user.roles).includes("montazni_skupina")
    : false;
  const haveWINDOWSRole = user
    ? JSON.stringify(user.roles).includes("WINDOWS")
    : false;
  const haveVykonStrediskRole = user
    ? JSON.stringify(user.roles).includes("vykon_stredisk")
    : false;
  const haveAccountingRole = user
    ? JSON.stringify(user.roles).includes("uctovnictvo")
    : false;
  const haveRezervaceAutRole = user
    ? JSON.stringify(user.roles).includes("rezervace_aut")
    : false;
  const haveObjednavkyRole = user
    ? JSON.stringify(user.roles).includes("objednavk")
    : false;
  const haveKalendarRole = user
    ? JSON.stringify(user.roles).includes("kalendar")
    : false;
  const [items, setItems] = useState([]);
  const [addItems, setAddItems] = useState([]);
  const [added, setAdded] = useState({
    organization: false,
    projektyXXl: false,
    reklamace: false,
    poruchy: false,
    obchod: false,
    detaily: false,
    objednavky: false,
    odmeny: false,
    montazniSkupina: false,
    WINDOWS: false,
    uctovnictvo: false,
    rezervaceAut: false,
    vykonStredisk: false,
    kalendar: false,
  });

  useEffect(() => {
    if (items.length === 0) {
      if (isAdmin) {
        setItems(menuItem.adminItems);
      }
      if (!isAdmin) {
        if (isCustomer) {
          setItems(menuItem.customerItems);
        }
      }
    }
  }, [items, isAdmin, isCustomer]);

  useEffect(() => {
    let addItemsLocal = [];
    //if (items.length === 0) return;
    if (haveObchodRole && !added.obchod) {
      addItemsLocal = addItemsLocal.concat(menuItem.defaultItems);
      setAdded((prevState) => {
        return { ...prevState, obchod: true };
      });
    }
    if (haveAccountingRole && !added.uctovnictvo) {
      addItemsLocal = addItemsLocal.concat(menuItem.accountingItems);
      setAdded((prevState) => {
        return { ...prevState, uctovnictvo: true };
      });
    }
    if (haveKalendarRole && !added.kalendar) {
      addItemsLocal = addItemsLocal.concat(menuItem.kalendarItems);
      setAdded((prevState) => {
        return { ...prevState, kalendar: true };
      });
    }
    if (haveVykonStrediskRole && !added.vykonStredisk) {
      addItemsLocal = addItemsLocal.concat(menuItem.vykonStrediskItems);
      setAdded((prevState) => {
        return { ...prevState, vykonStredisk: true };
      });
    }
    if (haveWINDOWSRole && !added.WINDOWS) {
      addItemsLocal = addItemsLocal.concat(menuItem.windowsItems);
      setAdded((prevState) => {
        return { ...prevState, WINDOWS: true };
      });
    }
    if (haveRezervaceAutRole && !added.rezervaceAut) {
      addItemsLocal = addItemsLocal.concat(menuItem.rezervacieAutItems);
      setAdded((prevState) => {
        return { ...prevState, rezervaceAut: true };
      });
    }
    if (haveMontazniSkupinaRole && !added.montazniSkupina) {
      addItemsLocal = addItemsLocal.concat(menuItem.montazniSkupinaItems);
      setAdded((prevState) => {
        return { ...prevState, montazniSkupina: true };
      });
    }
    if (haveObjednavkyRole && !added.objednavky) {
      addItemsLocal = addItemsLocal.concat(menuItem.objednavkyItems);
      setAdded((prevState) => {
        return { ...prevState, objednavky: true };
      });
    }
    if (haveOdmenyRole && !added.odmeny) {
      addItemsLocal = addItemsLocal.concat(menuItem.odmenyItems);
      setAdded((prevState) => {
        return { ...prevState, odmeny: true };
      });
    }
    if (havePoruchyRole && !added.poruchy) {
      addItemsLocal = addItemsLocal.concat(menuItem.poruchyItems);
      setAdded((prevState) => {
        return { ...prevState, poruchy: true };
      });
    }
    if (haveReklamaceRole && !added.reklamace) {
      addItemsLocal = addItemsLocal.concat(menuItem.reklamaceItems);
      setAdded((prevState) => {
        return { ...prevState, reklamace: true };
      });
    }
    if (haveProjektyXXLRole && !added.projektyXXl) {
      addItemsLocal = addItemsLocal.concat(menuItem.projektyXXLItems);
      setAdded((prevState) => {
        return { ...prevState, projektyXXl: true };
      });
    }
    if (haveOrganization && !added.organization) {
      addItemsLocal = addItemsLocal.concat(menuItem.organizacnyDiagramItems);
      setAdded((prevState) => {
        return { ...prevState, organization: true };
      });
    }
    if (haveDetailyRole && !added.detaily) {
      addItemsLocal = addItemsLocal.concat(menuItem.detailyItems);
      setAdded((prevState) => {
        return { ...prevState, detaily: true };
      });
    }
    setAddItems(addItemsLocal);
  }, [
    items,
    haveOrganization,
    haveObchodRole,
    haveProjektyXXLRole,
    haveReklamaceRole,
    havePoruchyRole,
    haveDetailyRole,
    haveOdmenyRole,
    haveMontazniSkupinaRole,
    haveWINDOWSRole,
    haveAccountingRole,
    added,
  ]);

  useEffect(() => {
    if (addItems.length === 0) return;
    setItems((prevState) => prevState.concat(addItems));
  }, [items, addItems]);

  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <MenuList items={items} />
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList items={items} />
        </Box>
      </MobileView>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "88px",
            },
            zIndex: 1049,
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;

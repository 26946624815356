// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconChartBar } from "@tabler/icons";

// constant
const icons = {
  IconChartBar,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const vykonMenu = {
  id: "vykonMenu",
  title: <FormattedMessage id="performance-panel" />,
  type: "group",
  children: [
    {
      id: "vykon",
      title: <FormattedMessage id="department-performance" />,
      mainTitle: <FormattedMessage id="department-performance" />,
      type: "item",
      url: "/app/vykon",
      icon: icons.IconChartBar,
      breadcrumbs: false,
    },
  ],
};

export default vykonMenu;

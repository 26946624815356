import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import { Outlet } from "react-router-dom";
import AccountingGuard from "../components/atoms/route-guard/AccountingGuard";

// dashboard routing
const AnalyzaProjektovPage = Loadable(
  lazy(() => import("../components/pages/ACCOUNTING/analyzaProjektov"))
);
const AnalyzaProjektuDetailPage = Loadable(
  lazy(() => import("../components/pages/ACCOUNTING/analyzaProjektov/detail"))
);
const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const AccountingRoutes = {
  path: "/",
  element: (
    <AccountingGuard>
      <Outlet />
    </AccountingGuard>
  ),
  children: [
    {
      path: "/app/analyza-projektov",
      element: <AnalyzaProjektovPage />,
    },
    {
      path: "/app/analyza-projektov/:idCenovejPonuky",
      element: <AnalyzaProjektuDetailPage />,
    },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default AccountingRoutes;

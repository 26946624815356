import React from "react";
import { SocketIoProvider } from "./utils/socketIo";
import { AuthProvider } from "./utils/auth";
import { ApiProvider } from "./utils/api";
//import { LanguageProvider } from "./utils/language";
//import { fetchTranslations } from "./utils/fetchTranslations";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import themes from "./themes";
//import { LayoutProvider } from "./utils/LayoutContext";
import { useSelector } from "react-redux";
import Locales from "./components/molecules/Berry/Locales";
import NavigationScroll from "./components/organisms/BerryLayout/NavigationScroll";
import Routes from "./routes";
import Snackbar from "./components/molecules/Berry/Snackbar";
import moment from "moment-business-time";

const App = () => {
  const customization = useSelector((state) => state.customization);
  moment.updateLocale("cs", {
    workinghours: {
      0: null,
      1: ["06:00:00", "14:00:00"],
      2: ["06:00:00", "14:00:00"],
      3: ["06:00:00", "14:00:00"],
      4: ["06:00:00", "14:00:00"],
      5: ["06:00:00", "14:00:00"],
      6: null,
    },
  });
  moment.updateLocale("en", {
    workinghours: {
      0: null,
      1: ["06:00:00", "14:00:00"],
      2: ["06:00:00", "14:00:00"],
      3: ["06:00:00", "14:00:00"],
      4: ["06:00:00", "14:00:00"],
      5: ["06:00:00", "14:00:00"],
      6: null,
    },
  });
  moment.updateLocale("sk", {
    workinghours: {
      0: null,
      1: ["06:00:00", "14:00:00"],
      2: ["06:00:00", "14:00:00"],
      3: ["06:00:00", "14:00:00"],
      4: ["06:00:00", "14:00:00"],
      5: ["06:00:00", "14:00:00"],
      6: null,
    },
  });

  return (
    <AuthProvider>
      <ApiProvider>
        <SocketIoProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
              {/** ADD LOCALES --- react-intl */}
              <Locales>
                <CssBaseline />
                <NavigationScroll>
                  <>
                    <Routes />
                    <Snackbar />
                  </>
                </NavigationScroll>
              </Locales>
            </ThemeProvider>
          </StyledEngineProvider>
        </SocketIoProvider>
      </ApiProvider>
    </AuthProvider>
  );
};

export default App;

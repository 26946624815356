import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// reducer import
import customizationReducer from "./customizationReducer";
import snackbarReducer from "./snackbarReducer";
import menuOpenReducer from "./menuOpenReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: persistReducer(
    { key: "theme", storage, keyPrefix: "NOFRAME_" },
    customizationReducer
  ),
  menuopen: menuOpenReducer,
  snackbar: snackbarReducer,
});

export default reducer;

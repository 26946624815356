// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconReportMoney, IconFileText, IconFileInvoice } from "@tabler/icons";

// constant
const icons = {
  IconReportMoney,
  IconFileText,
  IconFileInvoice,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const customerMenu = {
  id: "customerMenu",
  type: "group",
  children: [
    {
      id: "moje-cenove-nabidky",
      title: <FormattedMessage id="price-offer" />,
      mainTitle: <FormattedMessage id="price-offer" />,
      type: "item",
      url: "/app/moje-cenove-nabidky",
      icon: icons.IconReportMoney,
      breadcrumbs: false,
    },
    {
      id: "moje-smlouvy",
      title: <FormattedMessage id="contracts" />,
      mainTitle: <FormattedMessage id="contracts" />,
      type: "item",
      url: "/app/moje-smlouvy",
      icon: icons.IconFileText,
      breadcrumbs: false,
    },
    {
      id: "moje-faktury",
      title: <FormattedMessage id="invoices" />,
      mainTitle: <FormattedMessage id="invoices" />,
      type: "item",
      url: "/app/moje-faktury",
      icon: icons.IconFileInvoice,
      breadcrumbs: false,
    },
  ],
};

export default customerMenu;

// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconMessageReport, IconReport } from "@tabler/icons";

// constant
const icons = {
  IconMessageReport,
  IconReport,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const reklamaceMenu = {
  id: "reklamaceMenu",
  title: <FormattedMessage id="complaint-panel" />,
  type: "group",
  children: [
    {
      id: "reklamace",
      title: <FormattedMessage id="complaint" />,
      mainTitle: <FormattedMessage id="complaint" />,
      type: "item",
      url: "/app/reklamace",
      icon: icons.IconMessageReport,
      breadcrumbs: false,
    },
    // {
    //   id: "reklamace-report",
    //   title: <FormattedMessage id="complaint-report" />,
    //   mainTitle: <FormattedMessage id="complaint-report" />,
    //   type: "item",
    //   url: "/app/reklamace-report",
    //   icon: icons.IconReport,
    //   breadcrumbs: false,
    // },
  ],
};

export default reklamaceMenu;

// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconHierarchy } from "@tabler/icons";

// constant
const icons = {
  IconHierarchy,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const organizacnyDiagram = {
  id: "organizacnyDiagramMenu",
  title: <FormattedMessage id="organization" />,
  type: "group",
  children: [
    {
      id: "organizacny-diagram",
      title: <FormattedMessage id="organization-diagram" />,
      mainTitle: <FormattedMessage id="organization-diagram" />,
      type: "item",
      url: "/app/organizacny-diagram",
      icon: icons.IconHierarchy,
      breadcrumbs: false,
    },
  ],
};

export default organizacnyDiagram;

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// project imports
import { useAuth } from "../../../utils/auth";
import config from "../../../config";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SET_PATH } from "../../../store/actions";

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const isAdmin =
    user && user.roles && JSON.stringify(user.roles).includes("admin");
  const isCustomer =
    user &&
    user.roles &&
    JSON.stringify(user.roles).includes("zakaznik") &&
    user.roles.length === 1;
  const haveReklamaceRole =
    user && user.roles && JSON.stringify(user.roles).includes("reklamace");
  const havePoruchyRole =
    user && user.roles && JSON.stringify(user.roles).includes("poruchy");
  const haveProjektyXXLRole =
    user && user.roles && JSON.stringify(user.roles).includes("projektyXXL");
  const haveObchodRole =
    user && user.roles && JSON.stringify(user.roles).includes("obchod");
  const haveDetailyRole =
    user && user.roles && JSON.stringify(user.roles).includes("detaily");
  const haveOdmenyRole =
    user && user.roles && JSON.stringify(user.roles).includes("odmeny");
  const haveWindowsRole =
    user && user.roles && JSON.stringify(user.roles).includes("WINDOWS");
  const haveAccountingRole =
    user && user.roles && JSON.stringify(user.roles).includes("uctovnictvo");
  const haveMontazniSkupinaRole =
    user &&
    user.roles &&
    JSON.stringify(user.roles).includes("montazni_skupina");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      let path = null;
      if (isAdmin) {
        path = config.defaultPath;
      }
      if (!path && isCustomer) {
        path = config.customerDefaultPath;
      }
      if (!path && haveAccountingRole) {
        path = config.accountigDefaultPath;
      }
      if (!path && haveWindowsRole) {
        path = config.windowsDefaultPath;
      }
      if (!path && haveMontazniSkupinaRole) {
        path = config.montazniSkupinaDefaultPath;
      }
      if (!path && haveOdmenyRole) {
        path = config.odmenyDefaultPath;
      }
      if (!path && havePoruchyRole) {
        path = config.poruchyDefaultPath;
      }
      if (!path && haveReklamaceRole) {
        path = config.reklamaceDefaultPath;
      }
      if (!path && haveProjektyXXLRole) {
        path = config.projektyXXLDefaultPath;
      }
      if (!path && haveDetailyRole) {
        path = config.detailyDefaultPath;
      }
      if (haveObchodRole) {
        path = config.defaultPath;
      }
      dispatch({ type: SET_PATH, path: path });
      navigate(path);
    }
  }, [user, navigate]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;

import Swal from "sweetalert2";

export const errorHandler = (error, logout) => {
  var message = "";
  if (error.response && error.response.data && error.response.data.message) {
    message = error.response.data.message;
  }
  if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error !== "{}"
  ) {
    message = error.response.data.error;
  }
  if (
    error.response &&
    error.response.data &&
    error.response.data.errorString
  ) {
    message = error.response.data.errorString;
  }
  if (error.toString().includes("Network Error")) {
    message = "Databaza je dočasně nedostupna.\nSkuste to znovu.";
  }
  console.log(error.toString());
  if (logout || error.toString().includes("401")) {
    message = `Databaza je dočasně nedostupna.\nPre bezpecnost budete odhlaseny a presmerovany na login stranku\nBud ste zmenili obsah localstorage alebo Vas login token vyprsal`;
    Swal.fire({
      icon: "error",
      title: "Nastala chyba pri dopyte do databazy",
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
      text: message,
      willClose: () => {
        localStorage.clear();
        window.location.replace("/login");
      },
    });
    return;
  }
  Swal.fire({
    icon: "error",
    title: "Nastala chyba pri dopyte do databazy",
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    text: message,
  });
};

import { lazy } from "react";
import { Outlet } from "react-router-dom";
import ProjektyXXLGuard from "../components/atoms/route-guard/ProjektyXXLGuard";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";

// dashboard routing
const ProjektyXXLPage = Loadable(
  lazy(() =>
    import("../components/pages/CENOVE_NABIDKY_CUSTOM_FOR_OBS/projektyXXL")
  )
);
const ReportXXLPage = Loadable(
  lazy(() =>
    import("../components/pages/CENOVE_NABIDKY_CUSTOM_FOR_OBS/reportXXL")
  )
);
const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const ProjektyXXLRoutes = {
  path: "/",
  element: (
    <ProjektyXXLGuard>
      <Outlet />
    </ProjektyXXLGuard>
  ),
  children: [
    {
      path: "/app/projekty-xxl",
      element: <ProjektyXXLPage />,
    },
    {
      path: "/app/report-xxl",
      element: <ReportXXLPage />,
    },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default ProjektyXXLRoutes;

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconLayoutGridAdd,
  IconBuildingSkyscraper,
  IconUsers,
  IconAccessible,
  IconWindow,
  IconAt,
  IconBrandProducthunt,
  IconTag,
  IconBoxMultiple,
  IconNotes,
} from "@tabler/icons";

// constant
const icons = {
  IconLayoutGridAdd,
  IconBuildingSkyscraper,
  IconUsers,
  IconAccessible,
  IconWindow,
  IconAt,
  IconBrandProducthunt,
  IconTag,
  IconBoxMultiple,
  IconNotes,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const admin = {
  id: "dashboard",
  title: <FormattedMessage id="admin-panel" />,
  type: "group",
  children: [
    {
      id: "administrace",
      title: <FormattedMessage id="administrace" />,
      type: "collapse",
      icon: icons.IconUsers,
      children: [
        {
          id: "uzivatelia",
          title: <FormattedMessage id="users" />,
          type: "item",
          url: "/app/uzivatelia",
          breadcrumbs: false,
        },
        {
          id: "role",
          title: <FormattedMessage id="roles" />,
          type: "item",
          url: "/app/role",
          breadcrumbs: false,
        },
        {
          id: "skupiny",
          title: <FormattedMessage id="groups" />,
          type: "item",
          url: "/app/skupiny",
          breadcrumbs: false,
        },
        {
          id: "pozicie",
          title: <FormattedMessage id="positions" />,
          type: "item",
          url: "/app/pozicie",
          breadcrumbs: false,
        },
        {
          id: "organizacie",
          title: <FormattedMessage id="organizations" />,
          type: "item",
          url: "/app/organizacie",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "modulyC",
      title: <FormattedMessage id="moduly" />,
      type: "collapse",
      icon: icons.IconWindow,
      children: [
        {
          id: "moduly",
          title: <FormattedMessage id="modul-management" />,
          type: "item",
          url: "/app/moduly",
          breadcrumbs: false,
        },
        {
          id: "produkty",
          title: <FormattedMessage id="produkty" />,
          type: "item",
          url: "/app/produkty",
          breadcrumbs: false,
        },
        // {
        //   id: "kategorie",
        //   title: <FormattedMessage id="categories" />,
        //   type: "item",
        //   url: "/app/kategorie",
        //   breadcrumbs: false,
        // },
        {
          id: "parametre-modelov",
          title: <FormattedMessage id="model-parameters" />,
          type: "item",
          url: "/app/parametre-modelov",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "ostatne",
      title: <FormattedMessage id="ostatne" />,
      type: "collapse",
      icon: icons.IconBoxMultiple,
      children: [
        // {
        //   id: "zdroje-poptavky",
        //   title: <FormattedMessage id="zdroje-poptavky" />,
        //   type: "item",
        //   url: "/app/zdroje-poptavky",
        //   breadcrumbs: false,
        // },
        {
          id: "typ-aktivity",
          title: <FormattedMessage id="typ-aktivity" />,
          type: "item",
          url: "/app/typ-aktivity",
          breadcrumbs: false,
        },
        // {
        //   id: "moznosti-uspory",
        //   title: <FormattedMessage id="savings-possibilities" />,
        //   type: "item",
        //   url: "/app/moznosti-uspory",
        //   breadcrumbs: false,
        // },
        {
          id: "texty-cn",
          title: <FormattedMessage id="texty-cn" />,
          type: "item",
          url: "/app/texty-cn",
          breadcrumbs: false,
        },
        {
          id: "hero-image",
          title: <FormattedMessage id="hero-image" />,
          type: "item",
          url: "/app/hero-image",
          breadcrumbs: false,
        },
        // {
        //   id: "sprava-aut",
        //   title: <FormattedMessage id="car-management" />,
        //   type: "item",
        //   url: "/app/sprava-aut",
        //   breadcrumbs: false,
        // },
      ],
    },
    {
      id: "logs",
      title: <FormattedMessage id="Logs" />,
      type: "item",
      url: "/app/logs",
      icon: icons.IconNotes,
      breadcrumbs: false,
    },
  ],
};

export default admin;

import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import ReklamaceGuard from "../components/atoms/route-guard/ReklamaceGuard";
import { Outlet } from "react-router-dom";

// dashboard routing
const Reklamace = Loadable(lazy(() => import("../components/pages/reklamace")));
const ReklamaceDetail = Loadable(
  lazy(() => import("../components/pages/reklamace/detail"))
);
const ReklamaceReport = Loadable(
  lazy(() => import("../components/pages/reklamace/report"))
);

const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const ReklamaceRoutes = {
  path: "/",
  element: (
    <ReklamaceGuard>
      <Outlet />
    </ReklamaceGuard>
  ),
  children: [
    {
      path: "/app/reklamace",
      element: <Reklamace />,
    },
    {
      path: "/app/reklamace/:reklamaceId",
      element: <ReklamaceDetail />,
    },
    {
      path: "/app/reklamace-report",
      element: <ReklamaceReport />,
    },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default ReklamaceRoutes;

export const compareObjects = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const decryptResponse = (res) => {
  return JSON.parse(
    new Buffer.from(res.data.result, "base64").toString("utf8")
  );
};

export const compareExactValues = (a, b) => {
  let keys1 = Object.keys(a);
  let keys2 = Object.keys(b);
  return (
    keys1.length === keys2.length &&
    Object.keys(a).every((key) => a[key] == b[key])
  );
};

export const compareOnlyExistingValuesInA = (a, b) => {
  return Object.keys(a).every((key) => a[key] === b[key]);
};

export const createArrFromObj = (obj) => {
  let arr = [];
  Object.keys(obj).forEach((k) => {
    arr.push({ [k]: obj[k] });
  });
  return arr;
};

export const createObjFromArr = (arr) => {
  return arr.reduce((p, c) => {
    p = { ...p, [Object.keys(c)[0]]: c[Object.keys(c)[0]] };
    return p;
  }, {});
};

import { Outlet } from "react-router-dom";

// project imports
import Customization from "../Customization";

// ==============================|| MINIMAL LAYOUT ||============================== //

const LoginLayout = () => (
  <>
    <Outlet />
    <Customization />
  </>
);

export default LoginLayout;

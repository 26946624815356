import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import PoruchyGuard from "../components/atoms/route-guard/PoruchyGuard";
import { Outlet } from "react-router-dom";

// dashboard routing
const PoruchyPage = Loadable(
  lazy(() => import("../components/pages/PORUCHY_CUSTOM_FOR_OBS/poruchyPage"))
);
const PoruchyDetailPage = Loadable(
  lazy(() =>
    import("../components/pages/PORUCHY_CUSTOM_FOR_OBS/poruchyPage/detail")
  )
);
const StrojePage = Loadable(
  lazy(() => import("../components/pages/PORUCHY_CUSTOM_FOR_OBS/stroje"))
);
const StrojeDetailPage = Loadable(
  lazy(() => import("../components/pages/PORUCHY_CUSTOM_FOR_OBS/stroje/detail"))
);

const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const PoruchyRoutes = {
  path: "/",
  element: (
    <PoruchyGuard>
      <Outlet />
    </PoruchyGuard>
  ),
  children: [
    {
      path: "/app/poruchy",
      element: <PoruchyPage />,
    },
    {
      path: "/app/poruchy/:idPoruchy",
      element: <PoruchyDetailPage />,
    },
    {
      path: "/app/stroje",
      element: <StrojePage />,
    },
    {
      path: "/app/stroje/:idStroje",
      element: <StrojeDetailPage />,
    },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default PoruchyRoutes;

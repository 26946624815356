// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  isOpen: [], // for active default menu
};

// ==============================|| MENU OPEN REDUCER ||============================== //

const menuOpenReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };

    default:
      return state;
  }
};

export default menuOpenReducer;

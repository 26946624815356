// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconHome,
  IconFriends,
  IconReportMoney,
  IconListCheck,
  IconActivity,
  IconFileReport,
  IconNotebook,
  IconRuler2,
  IconLayoutList,
  IconPencil,
  IconHammer,
  IconCoin,
  IconTools,
  IconWand,
} from "@tabler/icons";

// constant
const icons = {
  IconHome,
  IconFriends,
  IconReportMoney,
  IconListCheck,
  IconActivity,
  IconFileReport,
  IconNotebook,
  IconRuler2,
  IconLayoutList,
  IconPencil,
  IconHammer,
  IconCoin,
  IconTools,
  IconWand,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const defaultMenu = {
  id: "defaultMenu",
  type: "group",
  children: [
    {
      id: "nastenka",
      title: <FormattedMessage id="dashboard" />,
      mainTitle: <FormattedMessage id="dashboard" />,
      type: "item",
      url: "/app/nastenka",
      icon: icons.IconHome,
      breadcrumbs: false,
    },
    {
      id: "ukoly",
      title: <FormattedMessage id="tasks" />,
      type: "item",
      url: "/app/ukoly",
      icon: icons.IconListCheck,
      breadcrumbs: false,
    },
    {
      id: "crm",
      title: <FormattedMessage id="CRM" />,
      type: "collapse",
      icon: icons.IconFriends,
      children: [
        {
          id: "obchodni-vztahy",
          title: <FormattedMessage id="customer" />,
          type: "item",
          url: "/app/obchodni-vztahy",
          breadcrumbs: false,
        },
        {
          id: "architekti",
          title: <FormattedMessage id="architects" />,
          type: "item",
          url: "/app/architekti",
          breadcrumbs: false,
        },
        {
          id: "kontakty",
          title: <FormattedMessage id="contacts" />,
          type: "item",
          url: "/app/kontakty",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "obchod",
      title: <FormattedMessage id="obchod" />,
      type: "collapse",
      icon: icons.IconReportMoney,
      children: [
        {
          id: "leady",
          title: <FormattedMessage id="leads" />,
          type: "item",
          url: "/app/leady",
          breadcrumbs: false,
        },
        {
          id: "cenove-nabidky",
          title: <FormattedMessage id="price-offer" />,
          type: "item",
          url: "/app/zakazky",
          breadcrumbs: false,
        },
        {
          id: "aktivity",
          title: <FormattedMessage id="activities" />,
          type: "item",
          url: "/app/aktivity",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "realizace",
      title: <FormattedMessage id="realizace" />,
      type: "collapse",
      icon: icons.IconTools,
      children: [
        {
          id: "projekty",
          title: <FormattedMessage id="projekty" />,
          type: "item",
          url: "/app/projekty",
          breadcrumbs: false,
        },
      ],
    },
    /*{
      id: "interni-projekty",
      title: <FormattedMessage id="intern-projects" />,
      type: "item",
      url: "/app/interni-projekty",
      icon: icons.IconWand,
      breadcrumbs: false,
    },*/
    /*{
      id: "katalog",
      title: <FormattedMessage id="catalog" />,
      type: "collapse",
      icon: icons.IconLayoutList,
      children: [
        {
          id: "konfigurator",
          title: <FormattedMessage id="konfigurator" />,
          type: "item",
          url: "/app/katalog",
          breadcrumbs: false,
        },
      ],
    },*/
    {
      id: "projekce",
      title: <FormattedMessage id="projekce" />,
      type: "collapse",
      icon: icons.IconPencil,
      children: [
        {
          id: "projektovani",
          title: <FormattedMessage id="projektovani" />,
          type: "item",
          url: "/app/projektovani",
          breadcrumbs: false,
        },
        {
          id: "vyhled-projekce",
          title: <FormattedMessage id="vyhled" />,
          type: "item",
          url: "/app/vyhled-projekce",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "montaz",
      title: <FormattedMessage id="montaz" />,
      type: "collapse",
      icon: icons.IconHammer,
      children: [
        {
          id: "Montaze",
          title: <FormattedMessage id="montaze" />,
          type: "item",
          url: "/app/montaze",
          breadcrumbs: false,
        },
        {
          id: "vyhled-montaze",
          title: <FormattedMessage id="vyhled" />,
          type: "item",
          url: "/app/vyhled-montaze",
          breadcrumbs: false,
        },
      ],
    },
  ],
};

const defaultMenu2 = {
  id: "defaultMenu",
  type: "group",
  children: [
    {
      id: "nastenka",
      title: <FormattedMessage id="dashboard" />,
      mainTitle: <FormattedMessage id="dashboard" />,
      type: "item",
      url: "/app/nastenka",
      icon: icons.IconHome,
      breadcrumbs: false,
    },
    {
      id: "crm",
      title: <FormattedMessage id="CRM" />,
      type: "group",
      children: [
        {
          id: "obchodni-vztahy",
          title: <FormattedMessage id="customer" />,
          type: "item",
          url: "/app/obchodni-vztahy",
          icon: icons.IconFriends,
          breadcrumbs: false,
        },
        {
          id: "architekti",
          title: <FormattedMessage id="architects" />,
          type: "item",
          url: "/app/architekti",
          icon: icons.IconPencil,
          breadcrumbs: false,
        },
        {
          id: "ukoly",
          title: <FormattedMessage id="tasks" />,
          type: "item",
          url: "/app/ukoly",
          icon: icons.IconListCheck,
          breadcrumbs: false,
        },
        {
          id: "aktivity",
          title: <FormattedMessage id="activities" />,
          type: "item",
          url: "/app/aktivity",
          icon: icons.IconActivity,
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "obchod",
      title: <FormattedMessage id="obchod" />,
      type: "group",
      children: [
        {
          id: "cenove-nabidky",
          title: <FormattedMessage id="contracts" />,
          type: "item",
          url: "/app/zakazky",
          breadcrumbs: false,
          icon: icons.IconReportMoney,
        },
      ],
    },
  ],
};

export default defaultMenu2;

import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import LoginLayout from "../components/organisms/BerryLayout/LoginLayout";
import GuestGuard from "../components/atoms/route-guard/GuestGuard";
import NavMotion from "../components/organisms/BerryLayout/NavMotion";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("../components/pages/loginBerry/Login"))
);
const AuthFirstLogin = Loadable(
  lazy(() => import("../components/pages/loginBerry/FirstLogin"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <NavMotion>
      <GuestGuard>
        <LoginLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/first-login",
      element: <AuthFirstLogin />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default LoginRoutes;

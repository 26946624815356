import { lazy } from "react";

// project imports
import Loadable from "../components/molecules/Berry/Loadable";
import VykonGuard from "../components/atoms/route-guard/VykonGuard";
import { Outlet } from "react-router-dom";

// dashboard routing
const VykonPage = Loadable(
  lazy(() => import("../components/pages/vykonOddeleni"))
);
const NotAllowedPage = Loadable(
  lazy(() => import("../components/pages/notAllowedPage"))
);
const Error = Loadable(lazy(() => import("../components/pages/errorPage")));
// ==============================|| MAIN ROUTING ||============================== //

const VykonRoutes = {
  path: "/",
  element: (
    <VykonGuard>
      <Outlet />
    </VykonGuard>
  ),
  children: [
    {
      path: "/app/vykon",
      element: <VykonPage />,
    },
    {
      path: "/app/not-allowed",
      element: <NotAllowedPage />,
    },
    {
      path: "/app/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ],
};

export default VykonRoutes;

// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconHierarchy } from "@tabler/icons";

// constant
const icons = {
  IconHierarchy,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const projektyXXLMenu = {
  id: "projektyXXLMenu",
  title: <FormattedMessage id="XXL" />,
  type: "group",
  children: [
    {
      id: "projekty-xxl",
      title: <FormattedMessage id="projekty-xxl" />,
      mainTitle: <FormattedMessage id="projekty-xxl" />,
      type: "item",
      url: "/app/projekty-xxl",
      icon: icons.IconHierarchy,
      breadcrumbs: false,
    },
    {
      id: "report-xxl",
      title: <FormattedMessage id="report-xxl" />,
      mainTitle: <FormattedMessage id="report-xxl" />,
      type: "item",
      url: "/app/report-xxl",
      icon: icons.IconHierarchy,
      breadcrumbs: false,
    },
  ],
};

export default projektyXXLMenu;
